import { StatusType, UrlQueryParams } from "./common";

export type ProductLabel = {
  id: string;
  name: string;
};

export enum TagType {
  Highlights = "highlights",
  Alergents = "alergents",
  Label = "labels",
}

export type ProductTag = {
  id: string;
  name: string;
  imageUrl?: string | null;
  type?: TagType;
  createdAt?: string;
};

export interface Product {
  id: string;
  name: string;
  name2?: string;
  description?: string | null;
  status?: StatusType;
  imageUrl?: string | null;
  carbs?: string | null;
  kcal: string | null;
  protein?: string | null;
  fat?: string | null;
  sugar?: string | null;
  plu: string | null;
  price: string | null;
  externalFiatPrice?: string;
  tax: string | null;
  fiber?: string | null;
  cholesterol?: string | null;
  zinc?: string | null;
  calsium?: string | null;
  pottasium?: string | null;
  vitaminA?: string | null;
  vitaminC?: string | null;
  companyId?: string;
  createdAt?: string;
  updatedAt?: string;
  tags?: ProductTag[];
  highlights?: ProductTag[];
  alergents?: ProductTag[];
  labels?: ProductTag[];
  discountPercentages?: string;
  discountPrice?: string;
  isNewArrival?: boolean;
}

export interface CreateProductRequest {
  companyId: string;
  data: Omit<Product, "id" | "highlights" | "alergents" | "labels"> & {
    highlights: string[];
    alergents: string[];
    labels: string[];
  };
}

export interface UpdateProductRequest {
  id: string;
  data: Omit<Product, "id" | "kcal" | "highlights" | "alergents" | "labels"> & {
    highlights: string[];
    alergents: string[];
    labels: string[];
  };
}

export type GetProductResponse = Product;

export interface DeleteProductsRequest {
  data: {
    ids: string[];
  };
}

export type GetProductTagsListResponse = ProductTag[];

export type CreateProductTagRequest = Omit<ProductTag, "id" | "createdAt">;

export type UpdateProductTagRequest = Omit<ProductTag, "createdAt">;

export interface DeleteProductTagRequest {
  id: string;
}

export interface GetProductsRequest {
  companyId: string;
  locationId: string;
  query: UrlQueryParams;
}

export interface GetProductsResponse {
  data: Product[];
  page: number;
  size: number;
  total: number;
  totalPages: number;
}

export type GetProductLabelsResponse = ProductLabel[];
