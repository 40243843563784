// Lib
import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import { useLazyGetMenuQuery } from "rtkQuery/query/menusAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification, useViewport } from "hooks";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { CategoryType, MenuCategoryType } from "types/menus";
// Constants
import { ADMIN_ROUTES } from "consts";
// Helpers
import { generateTags } from "helpers/dataHelpers";
// Utils
import { scrollToAnchor } from "utils/scrollToAnchor";
import { errorHandler } from "utils/errorHandler";
// Components
import { ArrowBackLink, Loader, MenuItemCard } from "components";
import { Select } from "components/Form";
import { Categories, Category } from "../components";
// Styled
import { FlexContainer, PageTitleContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import {
  PageHeadingContainer,
  PageStikyHeading,
  SelectContainer,
} from "./styled";

export const PreviewMenu: FC = () => {
  const { isTabletPortrait, isDesktop } = useViewport();

  const companyId = useAppSelector(getUserCompanyId);

  const { id } = useParams();

  const { openNotification } = useNotification();

  const [menuFormValues, setMenuFormValues] = useState<{
    location: string;
    name?: string;
  }>({
    location: "",
  });

  const [categoriesData, setCategoriesData] = useState<MenuCategoryType[]>([]);

  const [activeCategory, setActiveCagory] = useState<string>("");

  const { data: locationsData } = useGetLocationsQuery(
    { companyId },
    { skip: !companyId, refetchOnMountOrArgChange: true },
  );

  const [getMenu, { isLoading: isGetMenuLoading }] = useLazyGetMenuQuery();

  const getMenuData = async () => {
    try {
      const menuData = await getMenu({ menuId: id }).unwrap();

      const { categories, siteId, name } = menuData || {};

      const categoriesData: MenuCategoryType[] = categories.map(
        (c: CategoryType) => ({
          id: c.id,
          name: c.name,
          name2: c?.name2 || "",
          description: c.description,
          image: c.imageUrl,
          order: c.position,
          disabled: c.menuItems?.every(i => i.isDisabled === true) || false,
          items:
            c.menuItems?.map(i => ({
              id: i.id,
              product: {
                id: i.product.id,
                name: i.product.name,
                description: i.product.description,
                price: i.product.price,
                tax: i.product.tax,
                plu: i.product.plu,
                imageUrl: i.product.imageUrl,
                kcal: i.product.kcal,
                tags: i.product.tags,
                highlights: i.product.highlights,
                alergents: i.product.alergents,
                labels: i.product.labels,
              },
              modifiers: i.modifiers,
              order: i.position,
              checked: false,
              disabled: i?.isDisabled || false,
            })) || [],
        }),
      );

      setCategoriesData(categoriesData);
      setMenuFormValues({
        location: siteId,
        name,
      });
      setActiveCagory(categoriesData?.[0]?.id);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  useEffect(() => {
    getMenuData();
  }, []);

  const isScrolling = useRef(true);
  const timeout = useRef(null);

  const handleActiveCategory = (value: string) => {
    clearTimeout(timeout.current);
    isScrolling.current = false;
    setActiveCagory(value);
    scrollToAnchor(value, 304);

    timeout.current = setTimeout(() => {
      isScrolling.current = true;
    }, 1000);
  };

  const handleScroll = () => {
    if (!isScrolling.current) return;
    const targets = categoriesData
      .map(({ id }) => document.getElementById(id))
      .filter(Boolean);

    targets.forEach((el, index) => {
      const targetRect = targets[index]?.getBoundingClientRect();

      const isTargetVisible =
        (targetRect.top >= 350 && targetRect.top <= 550) ||
        (targetRect.bottom >= 350 && targetRect.bottom <= 550);

      if (isTargetVisible) {
        setActiveCagory(el.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeout.current);
    };
  }, [categoriesData]);

  const locations =
    locationsData?.length &&
    locationsData.map(el => ({ label: el.name, value: el.id }));

  const isLoading = isGetMenuLoading;

  return (
    <>
      {isLoading && <Loader />}

      <FlexContainer $column $gap={24}>
        <PageStikyHeading $column $gap={24} $stiky={isDesktop}>
          <PageHeadingContainer
            $column={isTabletPortrait}
            $gap={8}
            $align={isTabletPortrait ? "flex-start" : "center"}
            $justify="space-between"
          >
            <PageTitleContainer $column $gap={16}>
              <ArrowBackLink
                title="Back to Menus"
                path={ADMIN_ROUTES.MENUS.path}
              />
              <Typography.H1>{menuFormValues.name} Preview</Typography.H1>
            </PageTitleContainer>

            <SelectContainer>
              <Select
                label="Location"
                value={menuFormValues.location}
                options={locations}
                disabled
              />
            </SelectContainer>
          </PageHeadingContainer>

          <Categories
            isPreview
            categoriesData={categoriesData}
            activeCategory={activeCategory}
            handleActiveCategory={handleActiveCategory}
          />
        </PageStikyHeading>

        {categoriesData?.map(({ id, name, items, disabled }) => (
          <Category
            key={id}
            disabled={disabled}
            categoryId={id}
            isPreview
            title={name}
            categoryItems={items}
          >
            {!!items?.length &&
              items?.map(item => (
                <MenuItemCard
                  isPreview
                  key={item.id}
                  itemId={item.id}
                  image={item.product.imageUrl}
                  modifiers={item.modifiers}
                  title={item.product.name}
                  labels={item.product?.labels}
                  description={item.product.description}
                  kcal={item.product.kcal}
                  kwd={item.product.price}
                  tags={generateTags(item.product)}
                  checked={item?.checked}
                  disabled={item.disabled}
                />
              ))}
          </Category>
        ))}
      </FlexContainer>
    </>
  );
};
