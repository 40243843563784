import { GetProductResponse } from "types/products";
import { ProductFormTypes } from "../components/ProductForm/types";
import { StatusType } from "types/common";

export const createInitialData = (
  productData: GetProductResponse,
): ProductFormTypes => {
  const {
    id,
    companyId,
    createdAt,
    updatedAt,
    highlights,
    alergents,
    ...data
  } = productData;

  const highlightsValues = {};
  const alergentsValues = {};

  highlights?.forEach(tag => (highlightsValues[tag.id] = true));
  alergents?.forEach(tag => (alergentsValues[tag.id] = true));

  return {
    ...data,
    name2: data?.name2 || null,
    externalFiatPrice: data?.externalFiatPrice || "0",
    labels: data?.labels[0]?.id || "",
    status: data.status === StatusType.Active ? "active" : "disabled",
    highlights: highlightsValues,
    alergents: alergentsValues,
  };
};

export const getRequestTagsData = (
  selectedTags: Record<string, boolean | undefined>,
): string[] => {
  const tagIds = Object.keys(selectedTags).filter(
    key => selectedTags[key] === true,
  );

  if (!tagIds.length) {
    return [];
  }

  return tagIds;
};
