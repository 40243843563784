// Lib
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Api
import {
  useDeleteProductsMutation,
  useGetProductsQuery,
} from "rtkQuery/query/productsAPI";
import { useLazyGetLocationsQuery } from "rtkQuery/query/locationsAPI";
// Hooks
import { useNotification, usePermissions, useTable, useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { ETable } from "types/tableFilters";
import { MenuTableAction } from "./types";
// Theme
import { theme } from "theme";
// Constants
import { NOTIFICATIONS } from "consts";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { MenuIcon, PencilIcon, TrashIcon } from "icons";
// Components
import { ConfirmDialog, Pagination, Table } from "components";
import { Select } from "components/Form";
// Styled
import { FlexContainer, PageButtonsContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import { PageWrapper, SelectContainer, SelectedItems } from "./styled";

import { columns, items } from "./config";

export const Products: FC = () => {
  const { isDesktop } = useViewport();

  const location = useLocation();
  const navigate = useNavigate();

  const companyId = useAppSelector(getUserCompanyId);

  const { canReadProduct, canDeleteProduct } = usePermissions();

  const { openNotification } = useNotification();

  const [locationIdValue, setLocationIdValue] = useState<string>("");
  const [tab, setTab] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [showDeleteProductsModal, setShowDeleteProductsModal] =
    useState<boolean>(false);

  const deleteItemData = useRef<{ title: string; id: string } | null>(null);

  const [getLocations, { data: locationsData, isLoading: isLocationsLoading }] =
    useLazyGetLocationsQuery();

  useEffect(() => {
    if (!companyId) {
      return;
    }

    if (location?.state?.locationIdValue) {
      setLocationIdValue(location?.state?.locationIdValue);
    }

    if (location?.state?.tab) {
      setTab(location?.state?.tab);
    }

    getLocations({ companyId });
  }, []);

  const {
    orderDirection,
    orderBy,
    currentPage,
    pageSize,
    search,
    debouncedSearch,
    setCurrentPage,
    setPageSize,
    setSearch,
    handleSort,
  } = useTable({ name: ETable.Products });

  const {
    data: tableData,
    isFetching: isTableDataFetching,
    isError,
    error,
  } = useGetProductsQuery(
    {
      companyId,
      locationId: locationIdValue,
      query: {
        page: currentPage,
        size: pageSize,
        orderBy: orderBy,
        orderDirection: orderDirection,
        query: debouncedSearch,
        statuses: tab,
      },
    },
    { refetchOnMountOrArgChange: true, skip: !companyId || !locationIdValue },
  );

  useEffect(() => {
    if (isError) {
      errorHandler({ error, openNotification });
    }
  }, [isError]);

  const [deleteProduct] = useDeleteProductsMutation();

  const handleSetTab = (value: string) => {
    setSelectedRowKeys([]);
    setTab(value);
  };

  const actions: MenuTableAction[] = [
    {
      title: "",
      Icon: PencilIcon,
      disabled: !canReadProduct,
      onClick: ({ id }) =>
        navigate(`/loop/products/edit/${id}`, {
          state: { locationIdValue, tab },
        }),
    },
    {
      title: "",
      type: "Delete",
      Icon: TrashIcon,
      disabled: true,
      onClick: ({ id, name }) => {
        deleteItemData.current = {
          title: `${name} will be deleted`,
          id: id,
        };
        setShowDeleteProductsModal(true);
      },
    },
  ];

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const onSelectChange = (_: React.Key[], selectedRows: any[]) => {
    setSelectedRowKeys(selectedRows.map(({ id }) => id));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const cleanColumns = useMemo(() => {
    return columns.map((col, i) => ({
      ...col,
      title:
        i === 1 ? (
          <FlexContainer $align="center" style={{ marginLeft: "-12%" }}>
            <SelectedItems>
              {`${selectedRowKeys.length} data selected`}
            </SelectedItems>
            <Button.SubHeading
              onClick={() => setShowDeleteProductsModal(true)}
              style={{
                marginLeft: 24,
              }}
              icon={<TrashIcon fill={theme.color.red} />}
            >
              <span style={{ color: theme.color.red }}>Delete</span>
            </Button.SubHeading>
          </FlexContainer>
        ) : null,
      sorter: false,
    }));
  }, [selectedRowKeys.length]);

  const handleCloseModal = () => {
    deleteItemData.current = null;
    setShowDeleteProductsModal(false);
  };

  const onConfirmDelete = async () => {
    const ids = selectedRowKeys?.length
      ? selectedRowKeys
      : [deleteItemData.current.id];

    if (ids?.length) {
      try {
        await deleteProduct({ data: { ids } }).unwrap();

        openNotification({
          message: `${ids.length} product deleted successfully`,
        });

        setShowDeleteProductsModal(false);
        setSelectedRowKeys([]);
      } catch (error) {
        errorHandler({ error, openNotification });
      }
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const locations =
    locationsData?.length &&
    locationsData.map(el => ({ label: el.name, value: el.id }));

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $gap={20}
          $grow={1}
          $padding={isDesktop && "0 0 32px"}
        >
          <FlexContainer
            $align="center"
            $justify="space-between"
            $gap={8}
            $wrap
          >
            <Typography.H1>Products</Typography.H1>
            <PageButtonsContainer>
              {/* temporary hidden */}
              {/* {canCreateProduct && (
                <Button.Heading
                  type="primary"
                  icon={<PlusIcon fill={theme.color.white} />}
                  onClick={handleAddProduct}
                >
                  Add Product
                </Button.Heading>
              )} */}

              <SelectContainer>
                <Select
                  label="Location"
                  loading={isLocationsLoading}
                  value={locationIdValue}
                  options={locations}
                  onChange={value => setLocationIdValue(value)}
                />
              </SelectContainer>
            </PageButtonsContainer>
          </FlexContainer>

          <Table
            isLoading={isTableDataFetching}
            rowKey={record => record.id}
            rowSelection={canDeleteProduct && rowSelection}
            header={{
              tabs: { setTab: handleSetTab, items, tab: tab },
              search: {
                placeholder: "Product name",
                value: search,
                setValue: setSearch,
              },
            }}
            accentColumn={3}
            accentColumnWidth="48%"
            columns={selectedRowKeys.length ? cleanColumns : columns}
            dataSource={tableData?.data?.length && tableData.data}
            empty={{
              icon: MenuIcon,
              title: !locationIdValue
                ? "No location selected"
                : isTableDataFetching
                ? "Loading"
                : "No products found",
              description: !locationIdValue
                ? "Please select location."
                : isTableDataFetching
                ? ""
                : "Please add products",
            }}
            actions={actions}
            onChange={handleTableChange}
          />
        </FlexContainer>

        <Pagination
          onPage
          padding="12px 16px"
          pageSize={pageSize}
          showSizeChanger={!isDesktop}
          currentPage={currentPage}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          totalItems={tableData?.total}
        />

        <ConfirmDialog
          open={showDeleteProductsModal}
          Icon={TrashIcon}
          message={
            deleteItemData.current
              ? deleteItemData.current?.title
              : `${selectedRowKeys.length} item(s) will be deleted`
          }
          description={NOTIFICATIONS.APROVE_ACTION_MESSAGE}
          firstCTAButton={{
            title: "Delete Products",
            status: "danger",
            onClick: () => onConfirmDelete(),
          }}
          onCancel={handleCloseModal}
        />
      </PageWrapper>
    </>
  );
};
