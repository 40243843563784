// Lib
import { FC, forwardRef, useRef } from "react";
// Api
import { SelectProps as AntDSelectProps, InputRef, RefSelectProps } from "antd";
import { ControllerFieldState } from "react-hook-form";
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
import { SearchBlackIcon } from "icons";
// Layouts
// Components
import { Input } from "../Input";
// Styled
import { Typography } from "styled/Typography";
import { FlexContainer } from "styled/Box";
import { SelectField, ErrorMessage } from "../styled";

interface SearchSelectProps extends AntDSelectProps {
  phone?: boolean;
  required?: boolean;
  label?: string;
  fieldState?: ControllerFieldState;
  fieldError?: boolean | string;
  styles?: object;
  searchPlaceHolder?: string;
  searchValue?: string;
  onSearchChange?: (value: string) => void;
}

export const SearchSelect: FC<SearchSelectProps> = forwardRef<
  RefSelectProps,
  SearchSelectProps
>(
  (
    {
      required,
      phone,
      label,
      fieldError,
      fieldState,
      searchPlaceHolder,
      searchValue,
      onSearchChange,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<InputRef>(null);

    const error = fieldError || fieldState?.error?.message || false;

    return (
      <div>
        {!!label && (
          <Typography.Label>
            {label}
            {!!required && "*"}
          </Typography.Label>
        )}
        <SelectField
          ref={ref}
          $phone={phone}
          status={!!error && "error"}
          {...props}
          dropdownRender={menu => (
            <FlexContainer $fullwidth $column $gap={8} $padding="12px 12px 4px">
              <Input
                ref={inputRef}
                dir="auto"
                placeholder={searchPlaceHolder}
                prefix={<SearchBlackIcon />}
                value={searchValue}
                onChange={e => onSearchChange(e.target.value)}
              />
              {menu}
            </FlexContainer>
          )}
          onDropdownVisibleChange={open => !open && onSearchChange("")}
        />
        {typeof error === "string" && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    );
  },
);

SearchSelect.displayName = "Select";
