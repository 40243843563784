// Lib
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverEvent,
} from "@dnd-kit/core";
// Api
import {
  useCreateMenuMutation,
  useLazyGetMenuQuery,
  usePublishMenuMutation,
  useUpdateMenuMutation,
} from "rtkQuery/query/menusAPI";
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification, usePermissions, useViewport } from "hooks";
// Actions
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import {
  MenuCategoryItemType,
  MenuCategoryType,
  EditCategoryFormTypes,
  MenuFormTypes,
  CategoryType,
  MenuItemFormTypes,
  PublishOutOfStock,
} from "types/menus";
import { Product } from "types/products";
import { Modifier } from "types/modifiers";
import { MenuTypesOptions } from "types/common";
import { CheckedItem, IDeleteModal } from "./types";
// Theme
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS } from "consts";
// Helpers
import { errorHandler } from "utils/errorHandler";
import { generateUniqueId, generateTags, getLabel } from "helpers/dataHelpers";
// Utils
import { scrollToAnchor } from "utils/scrollToAnchor";
// Icons
import { ProductIcon, RightBurgerMenuIcon, TrashIcon } from "icons";
// Components
import {
  ArrowBackLink,
  ConfirmDialog,
  DropDown,
  Loader,
  MenuItemCard,
} from "components";
import {
  Categories,
  Category,
  CategoryForm,
  MenuForm,
  CategoryItemForm,
  AddItemsModal,
} from "../components";
// Styled
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import { FlexContainer, PageTitleContainer } from "styled/Box";
import {
  PageHeadingContainer,
  PageStikyCategories,
  PageStikyHeading,
} from "./styled";

const unsavedParam = {
  unsaved: "true",
};

export const EditMenu: FC = () => {
  const { isDesktop } = useViewport();

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const isEdit = !!id;

  const navigate = useNavigate();

  const companyId = useAppSelector(getUserCompanyId);

  const { canUpdateMenu, canCreateMenu } = usePermissions();

  if ((isEdit && !canUpdateMenu) || (!isEdit && !canCreateMenu)) {
    navigate(ADMIN_ROUTES.MENUS.path);
  }

  const { openNotification } = useNotification();

  const savedMenuIdRef = useRef<string>("");

  const { data: locationsData } = useGetLocationsQuery(
    { companyId },
    { skip: !companyId, refetchOnMountOrArgChange: true },
  );

  const [getMenu, { isLoading: isGetMenuLoading }] = useLazyGetMenuQuery();

  const [updateMenu, { isLoading: isUpdateMenuLoading }] =
    useUpdateMenuMutation();

  const [createMenu, { isLoading: isCreateMenuLoading }] =
    useCreateMenuMutation();

  const [publishMenu, { isLoading: isPublishMenuLoading }] =
    usePublishMenuMutation();

  const [menuFormValues, setMenuFormValues] = useState<MenuFormTypes>({
    name: "",
    internalName: "",
    internalType: MenuTypesOptions.Delivery,
    location: "",
    workStartTime: "",
    workEndTime: "",
  });

  const [categoriesData, setCategoriesData] = useState<MenuCategoryType[]>([]);

  const [unavailableItemsModal, setUnavailableItemsModal] = useState({
    id: "",
    show: false,
    count: 0,
  });

  const getMenuData = async () => {
    try {
      const menuData = await getMenu({ menuId: id }).unwrap();

      const {
        name,
        internalType,
        internalName,
        categories,
        siteId,
        workStartTime,
        workEndTime,
      } = menuData || {};

      const categoriesData: MenuCategoryType[] = categories.map(
        (c: CategoryType) => ({
          id: c.id,
          name: c.name,
          name2: c?.name2 || "",
          description: c.description,
          image: c.imageUrl,
          order: c.position,
          disabled: c.isDisabled || false,
          items:
            c.menuItems.map(i => ({
              id: i.id,
              product: {
                id: i.product.id,
                name: i.product.name,
                description: i.product.description,
                price: i.product.price,
                tax: i.product.tax,
                plu: i.product.plu,
                imageUrl: i.product.imageUrl,
                kcal: i.product.kcal,
                tags: i.product.tags,
                highlights: i.product.highlights,
                alergents: i.product.alergents,
                labels: i.product.labels,
              },
              modifiers: i?.modifiers || [],
              order: i.position,
              checked: false,
              disabled: i?.isDisabled || false,
            })) || [],
        }),
      );

      setMenuFormValues({
        name,
        internalType,
        internalName,
        location: siteId,
        workStartTime,
        workEndTime,
      });
      setCategoriesData(categoriesData);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const onPublish = async (
    id: string,
    outOfStock: PublishOutOfStock,
  ): Promise<boolean> => {
    try {
      const response = await publishMenu({
        id,
        data: { outOfStock },
      }).unwrap();

      if (response?.outOfStockItems?.length > 0) {
        setUnavailableItemsModal({
          id,
          show: true,
          count: response.outOfStockItems.length,
        });

        return false;
      }

      return true;
    } catch (error) {
      errorHandler({ error, openNotification });

      return false;
    }
  };

  const onSave = async (withPublish?: boolean): Promise<boolean> => {
    const invalidCategory = categoriesData?.find(cat => !cat?.items?.length);

    if (invalidCategory) {
      openNotification({
        message: `${invalidCategory?.name} category must have at least 1 item`,
        type: "error",
      });

      return false;
    }

    try {
      if (isEdit) {
        const response = await updateMenu({
          menuId: id,
          data: {
            name: menuFormValues.name,
            internalName: menuFormValues.internalName,
            internalType: menuFormValues.internalType,
            siteId: menuFormValues.location,
            workStartTime: menuFormValues.workStartTime,
            workEndTime: menuFormValues.workEndTime,
            categories: categoriesData?.map((cat, i) => {
              if (cat?.isNew) {
                return {
                  menuId: id,
                  name: cat.name,
                  name2: cat?.name2 || "",
                  description: cat?.description || null,
                  position: i,
                  imageUrl: cat?.image || null,
                  isDisabled: cat.disabled || false,
                  menuItems: cat.items.map((item, i) => {
                    return {
                      productId: item.product.id,
                      position: i,
                      modifiers: item?.modifiers?.map(m => m.id) || [],
                      isDisabled: item?.disabled,
                    };
                  }),
                };
              } else {
                return {
                  menuId: id,
                  id: cat.id,
                  name: cat.name,
                  name2: cat?.name2 || "",
                  description: cat?.description || null,
                  position: i,
                  imageUrl: cat?.image || null,
                  isDisabled: cat.disabled || false,
                  menuItems: cat.items?.map((item, i) => {
                    if (item?.isNew) {
                      return {
                        productId: item.product.id,
                        position: i,
                        modifiers: item?.modifiers?.map(m => m.id) || [],
                        isDisabled: item?.disabled,
                      };
                    } else {
                      return {
                        id: item.id,
                        productId: item.product.id,
                        position: i,
                        modifiers: item?.modifiers?.map(m => m.id) || [],
                        isDisabled: item?.disabled,
                      };
                    }
                  }),
                };
              }
            }),
          },
        }).unwrap();

        setSearchParams({ unsaved: "false" });

        if (withPublish) {
          const result = await onPublish(
            response.id,
            PublishOutOfStock.NOT_IGNORE,
          );

          return result;
        }

        return true;
      } else {
        const response = await createMenu({
          companyId,
          data: {
            name: menuFormValues.name,
            internalName: menuFormValues.internalName,
            internalType: menuFormValues.internalType,
            siteId: menuFormValues.location,
            workStartTime: menuFormValues.workStartTime,
            workEndTime: menuFormValues.workEndTime,
            categories: categoriesData?.map((cat, i) => ({
              name: cat.name,
              name2: cat?.name2 || "",
              description: cat.description,
              position: i,
              imageUrl: cat.image,
              menuItems: cat.items.map((item, i) => ({
                position: i,
                productId: item.product.id,
                modifiers: item?.modifiers?.map(m => m.id) || [],
                isDisabled: item?.disabled,
              })),
            })),
          },
        }).unwrap();

        savedMenuIdRef.current = response.id;

        setSearchParams({ unsaved: "false" });

        if (withPublish) {
          const result = await onPublish(
            response.id,
            PublishOutOfStock.NOT_IGNORE,
          );
          return result;
        }

        return true;
      }
    } catch (error) {
      errorHandler({ error, openNotification });

      return false;
    }
  };

  const onSaveClick = async () => {
    const isOk = await onSave();

    if (isOk) {
      openNotification({ message: NOTIFICATIONS.MENU_SAVED });

      setTimeout(() => navigate(ADMIN_ROUTES.MENUS.path), 1000);
    }
  };

  const onPublishClick = async () => {
    if (searchParams.get("unsaved") === "true") {
      const isOk = await onSave(true);

      if (isOk) {
        openNotification({ message: NOTIFICATIONS.MENU_PUBLISHED });

        setTimeout(() => navigate(ADMIN_ROUTES.MENUS.path), 1000);
      }
    } else {
      const menuId = id || savedMenuIdRef.current;

      const isOk = await onPublish(menuId, PublishOutOfStock.NOT_IGNORE);

      if (isOk) {
        openNotification({ message: NOTIFICATIONS.MENU_PUBLISHED });

        setTimeout(() => navigate(ADMIN_ROUTES.MENUS.path), 1000);
      }
    }
  };

  const onInventoryClick = () => {
    setTimeout(() =>
      navigate("/loop/inventory?tab=out_of_stock", {
        state: {
          locationIdValue: menuFormValues?.location,
          menuIdValue: id,
        },
      }),
    );
  };

  const onPublishConfirm = async () => {
    const isOk = await onPublish(
      id || unavailableItemsModal?.id,
      PublishOutOfStock.IGNORE,
    );

    if (isOk) {
      setUnavailableItemsModal(prev => ({ ...prev, count: 0 }));

      openNotification({ message: NOTIFICATIONS.MENU_PUBLISHED });

      setTimeout(() => navigate(ADMIN_ROUTES.MENUS.path));
    }
  };

  useEffect(() => {
    if (!isEdit) return;

    getMenuData();
  }, []);

  const checkedItems = useRef<null | CheckedItem[]>(null);

  const [activeCategory, setActiveCagory] = useState<string>("");

  const [showCategoryModal, setShowCategoryModal] = useState<
    boolean | MenuCategoryType
  >(false);

  const [showCategoryItemModal, setShowCategoryItemModal] = useState<
    boolean | { categoryId: string; item: MenuCategoryItemType }
  >(false);

  const [showCategoryItemsModal, setShowCategoryItemsModal] = useState<
    false | { categoryId: string; categoryName: string }
  >(false);

  const [deleteItemsModal, setDeleteItemsModal] = useState<null | IDeleteModal>(
    null,
  );

  const isScrolling = useRef(true);
  const timeout = useRef(null);

  const handleActiveCategory = (value: string) => {
    clearTimeout(timeout.current);
    isScrolling.current = false;
    setActiveCagory(value);
    scrollToAnchor(value, 304);

    timeout.current = setTimeout(() => {
      isScrolling.current = true;
    }, 1000);
  };

  const handleScroll = () => {
    if (!isScrolling.current) return;

    const targets = categoriesData
      .map(({ id }) => document.getElementById(id))
      .filter(Boolean);

    targets.forEach((el, index) => {
      const targetRect = targets[index].getBoundingClientRect();
      const isTargetVisible =
        (targetRect.top >= 350 && targetRect.top <= 550) ||
        (targetRect.bottom >= 350 && targetRect.bottom <= 550);

      if (isTargetVisible) {
        setActiveCagory(el.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeout.current);
    };
  }, [categoriesData]);

  const handleFormFieldChange = (values: MenuFormTypes) => {
    setMenuFormValues(values);
    setSearchParams(unsavedParam);
  };

  const handleCategoriesData = (value: MenuCategoryType[]) => {
    setCategoriesData(value);
  };

  const handleOpenCategoryModal = (args: boolean | string) => {
    if (typeof args === "boolean") {
      setShowCategoryModal(args);
      return;
    }
    const category = categoriesData.find(({ id }) => id === args);
    setShowCategoryModal(category);
  };

  const handleCloseCategoryModal = () => {
    setShowCategoryModal(false);
  };

  const handleAddCategory = (args: EditCategoryFormTypes) => {
    const { name, name2, description, image } = args;
    setCategoriesData(prev =>
      prev.concat({
        id: `temp-${generateUniqueId()}`,
        image,
        name,
        name2,
        description,
        order: prev?.length || 0,
        items: [],
        isNew: true,
      }),
    );
    openNotification({
      message: "Category has been added",
    });
    setSearchParams(unsavedParam);
  };

  const handleEditCategoryData = (
    categoryId: string,
    args: EditCategoryFormTypes,
  ) => {
    const { name, name2, description, image } = args;
    setCategoriesData(prev => {
      const currentCategoryIndx = prev.findIndex(cat => cat.id === categoryId);

      const newArray = [...prev];
      newArray[currentCategoryIndx].name = name;
      newArray[currentCategoryIndx].name2 = name2;
      newArray[currentCategoryIndx].description = description;
      newArray[currentCategoryIndx].image = image;

      return newArray;
    });

    setSearchParams(unsavedParam);

    openNotification({
      message: "Category has been updated",
    });
  };

  const handleOpenCategoryItemsModal = (data: {
    categoryId: string;
    categoryName: string;
  }) => {
    setShowCategoryItemsModal(data);
  };

  const handleCloseCategoryItemsModal = () => {
    setShowCategoryItemsModal(false);
  };

  const handleOpenCategoryItemModal = (categoryId: string, itemId: string) => {
    const category = categoriesData.find(({ id }) => id === categoryId);
    const item = category.items.find(el => el.id === itemId);

    setShowCategoryItemModal({ categoryId, item });
  };

  const handleClosePublishConfirmModal = () => {
    setUnavailableItemsModal(prev => ({ ...prev, show: false }));
  };

  const handleCloseCategoryItemModal = () => {
    setShowCategoryItemModal(false);
  };

  const handleSaveCategoryItem = async (
    data: MenuItemFormTypes,
    modifiers: Modifier[],
  ) => {
    if (typeof showCategoryItemModal === "boolean") return;

    try {
      //temporary removed
      // await updateProduct({
      //   id: showCategoryItemModal.item.product.id,
      //   data: {
      //     ...data,
      //     imageUrl: data.image,
      //     description: data.description,
      //     name: data.name,
      //     plu: data.plu,
      //     price: data.price,
      //     tax: data.tax,
      //     alergents: [],
      //     highlights: [],
      //     labels: [],
      //   },
      // }).unwrap();

      setCategoriesData(prev => {
        const currentCategoryIndx = prev.findIndex(
          cat => cat.id === showCategoryItemModal.categoryId,
        );

        const prevArray = [...prev];
        const categoryItems = prevArray[currentCategoryIndx].items;
        const currentItem = categoryItems.findIndex(
          el => el.id === showCategoryItemModal.item.id,
        );

        prevArray[currentCategoryIndx].items[currentItem] = {
          ...prevArray[currentCategoryIndx].items[currentItem],
          product: {
            ...prevArray[currentCategoryIndx].items[currentItem].product,
            ...data,
          },
          modifiers,
        };

        return prevArray;
      });

      openNotification({
        message: "Menu item has been saved",
      });

      handleCloseCategoryItemModal();
      setSearchParams(unsavedParam);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleCategoryItemsData = (
    id: string,
    value: MenuCategoryItemType[],
  ) => {
    setCategoriesData(prev => {
      const currentCategoryIndx = prev.findIndex(cat => cat.id === id);

      const newArray = [...prev];
      newArray[currentCategoryIndx].items = value;

      return newArray;
    });

    setSearchParams(unsavedParam);
  };

  const handleDisableCategory = (id: string, arg: boolean) => {
    setCategoriesData(prev => {
      const currentCategoryIndx = prev.findIndex(cat => cat.id === id);

      const newArray = [...prev];
      newArray[currentCategoryIndx].disabled = arg;

      newArray[currentCategoryIndx].items = newArray[
        currentCategoryIndx
      ].items.map(item => ({ ...item, disabled: arg }));

      return newArray;
    });

    if (arg) {
      openNotification({
        message: "Category has been disabled",
      });
    }
    setSearchParams(unsavedParam);
  };

  const handleDisableCategoryItem = (categoryId, itemId) => {
    setCategoriesData(prev => {
      const currentCategoryIndx = prev.findIndex(cat => cat.id === categoryId);

      const prevArray = [...prev];
      const categoryItems = prevArray[currentCategoryIndx].items;
      const currentItem = categoryItems.findIndex(el => el.id === itemId);

      prevArray[currentCategoryIndx].items[currentItem] = {
        ...prevArray[currentCategoryIndx].items[currentItem],
        disabled: !prevArray[currentCategoryIndx].items[currentItem].disabled,
      };

      if (!prevArray[currentCategoryIndx].items[currentItem].disabled) {
        prevArray[currentCategoryIndx].disabled = false;
      }

      return prevArray;
    });

    setSearchParams(unsavedParam);
  };

  const handleDeleteCategory = (id: string) => {
    setDeleteItemsModal({
      categoryId: id,
      message: NOTIFICATIONS.DELETE_CATEGORY_MESSAGE,
      description: NOTIFICATIONS.APROVE_ACTION_MESSAGE,
      buttonTitle: "Delete Category",
    });
  };

  const handleCloseDeleteModal = () => {
    setDeleteItemsModal(null);
  };

  const handleDeleteCategoryItem = (categoryId: string, itemIds: string[]) => {
    setDeleteItemsModal({
      categoryId: categoryId,
      itemsId: itemIds,
      message: `${itemIds?.length} ${NOTIFICATIONS.DELETE_ITEMS_MESSAGE}`,
      description: NOTIFICATIONS.APROVE_ACTION_MESSAGE,
      buttonTitle: `Delete ${itemIds?.length} ${
        itemIds?.length > 1 ? "items" : "item"
      }`,
    });
  };

  const handleDeleteCurrentCategoryItem = () => {
    if (typeof showCategoryItemModal === "boolean") return;

    handleDeleteCategoryItem(showCategoryItemModal.categoryId, [
      showCategoryItemModal.item.id,
    ]);
    handleCloseCategoryItemModal();
  };

  const handleCheckItem = (
    categoryId: string,
    itemId: string,
    value: boolean,
  ) => {
    if (!checkedItems.current) {
      checkedItems.current = [{ categoryId, itemsId: [itemId] }];
    }

    const newArray = [...checkedItems.current];
    const isCurrentCategoryHasCheckedItems = !!newArray.find(
      el => el.categoryId === categoryId,
    );

    if (value) {
      if (isCurrentCategoryHasCheckedItems) {
        const checkedItemsCategoryIndx = newArray.findIndex(
          el => el.categoryId === categoryId,
        );

        if (newArray[checkedItemsCategoryIndx]?.itemsId?.includes(itemId)) {
          checkedItems.current = newArray;
        } else {
          newArray[checkedItemsCategoryIndx].itemsId = newArray[
            checkedItemsCategoryIndx
          ].itemsId.concat([itemId]);

          checkedItems.current = newArray;
        }
      } else {
        checkedItems.current = newArray.concat([
          { categoryId, itemsId: [itemId] },
        ]);
      }
    } else {
      const checkedItemsCategoryIndx = newArray.findIndex(
        el => el.categoryId === categoryId,
      );

      if (newArray[checkedItemsCategoryIndx]?.itemsId?.length > 1) {
        const newItemsArray = newArray[checkedItemsCategoryIndx].itemsId.filter(
          el => el !== itemId,
        );
        newArray[checkedItemsCategoryIndx].itemsId = newItemsArray;

        checkedItems.current = newArray;
      } else {
        checkedItems.current = newArray.filter(
          el => el.categoryId !== categoryId,
        );
      }
    }

    setCategoriesData(prev => {
      const currentCategoryIndx = prev.findIndex(cat => cat.id === categoryId);

      const prevArray = [...prev];
      const categoryItems = prevArray[currentCategoryIndx].items;
      const currentItem = categoryItems.findIndex(el => el.id === itemId);

      prevArray[currentCategoryIndx].items[currentItem] = {
        ...prevArray[currentCategoryIndx].items[currentItem],
        checked: !prevArray[currentCategoryIndx].items[currentItem].checked,
      };

      return prevArray;
    });
  };

  const hasCheckedItems = categoriesData.find(category =>
    category.items.find(el => el.checked),
  );

  const checkedItemsNumber = checkedItems?.current
    ?.map(el => el.itemsId.length)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const handleDeleteCheckedItems = () => {
    setDeleteItemsModal({
      categoriesItems: checkedItems?.current,
      message: `${checkedItemsNumber} ${NOTIFICATIONS.DELETE_DIFFERENT_ITEMS_MESSAGE}`,
      description: NOTIFICATIONS.APROVE_ACTION_MESSAGE,
      buttonTitle: `Delete ${checkedItemsNumber} ${
        checkedItemsNumber > 1 ? "items" : "item"
      }`,
    });
  };

  const onDeleteConfirm = () => {
    if (deleteItemsModal?.categoriesItems) {
      setCategoriesData(prev => {
        const newArray = [...prev];

        const categoriesIdsList = deleteItemsModal?.categoriesItems.map(
          el => el.categoryId,
        );

        return newArray.reduce((acc, category) => {
          if (categoriesIdsList.includes(category.id)) {
            const deleteItemsList = deleteItemsModal?.categoriesItems.find(
              el => el.categoryId === category.id,
            ).itemsId;

            const newItemsArray = category.items.filter(
              item => !deleteItemsList.includes(item.id),
            );

            return [...acc, { ...category, items: newItemsArray }];
          }
          return [...acc, category];
        }, []);
      });
      openNotification({
        message: `${checkedItems.current?.length} menu items has been deleted`,
      });
    }
    if (deleteItemsModal?.itemsId?.length) {
      setCategoriesData(prev => {
        const currentCategoryIndx = prev.findIndex(
          cat => cat.id === deleteItemsModal.categoryId,
        );

        const newArray = [...prev];
        newArray[currentCategoryIndx].items = prev[
          currentCategoryIndx
        ].items.filter(item => !deleteItemsModal.itemsId.includes(item.id));

        return newArray;
      });

      openNotification({
        message: `${deleteItemsModal?.itemsId?.length} menu items has been deleted`,
      });
    }
    if (deleteItemsModal.categoryId && !deleteItemsModal.itemsId) {
      setCategoriesData(prev => {
        return prev.filter(cat => cat.id !== deleteItemsModal.categoryId);
      });

      openNotification({
        message: "Category has been deleted",
      });
    }
    checkedItems.current = null;
    handleCloseCategoryItemModal();
    setDeleteItemsModal(null);
    setSearchParams(unsavedParam);
  };

  const handleAddItemsToCategory = (categoryId: string, items: Product[]) => {
    setCategoriesData(prev => {
      const currentCategoryIndx = prev.findIndex(cat => cat.id === categoryId);

      const newArray = [...prev];

      const newItems = items.map(i => ({
        disabled: false,
        checked: false,
        order: newArray[currentCategoryIndx]?.items?.length,
        product: {
          id: i.id,
          name: i.name,
          description: i.description,
          price: i.price,
          tax: i.tax,
          plu: i.plu,
          imageUrl: i.imageUrl,
          kcal: i.kcal,
          tags: i.tags,
          highlights: i.highlights,
          alergents: i.alergents,
          labels: i.labels,
        },
        modifiers: [],
        id: `temp-${generateUniqueId()}`,
        isNew: true,
      }));

      newArray[currentCategoryIndx].items = [
        ...prev[currentCategoryIndx].items,
        ...newItems,
      ];

      openNotification({
        message: `${items?.length} menu items has been added to ${newArray[currentCategoryIndx].name}`,
      });

      return newArray;
    });

    setSearchParams(unsavedParam);

    handleCloseCategoryItemsModal();
  };

  //dnd

  const findColumn = (unique: string | null) => {
    if (!unique) {
      return null;
    }
    if (categoriesData.some(c => c.id === unique)) {
      return categoriesData.find(c => c.id === unique) ?? null;
    }
    const id = String(unique);
    const itemWithColumnId = categoriesData.flatMap(c => {
      const columnId = c.id;
      return c.items.map(i => ({ itemId: i.id, columnId: columnId }));
    });
    const columnId = itemWithColumnId.find(i => i.itemId === id)?.columnId;
    return categoriesData.find(c => c.id === columnId) ?? null;
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over, delta } = event;
    const activeId = String(active.id);
    const overId = over ? String(over.id) : null;
    const activeColumn = findColumn(activeId);
    const overColumn = findColumn(overId);
    if (!activeColumn || !overColumn || activeColumn === overColumn) {
      return null;
    }
    setCategoriesData(prev => {
      const activeItems = activeColumn.items;
      const overItems = overColumn.items;
      const activeIndex = activeItems.findIndex(i => i.id === activeId);
      const overIndex = overItems.findIndex(i => i.id === overId);
      const newIndex = () => {
        const putOnBelowLastItem =
          overIndex === overItems.length - 1 && delta.y > 0;
        const modifier = putOnBelowLastItem ? 1 : 0;
        return overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      };
      return prev.map(c => {
        if (c.id === activeColumn.id) {
          c.items = activeItems.filter(i => i.id !== activeId);
          return c;
        } else if (c.id === overColumn.id) {
          c.items = [
            ...overItems.slice(0, newIndex()),
            activeItems[activeIndex],
            ...overItems.slice(newIndex(), overItems.length),
          ];
          return c;
        } else {
          return c;
        }
      });
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const activeId = String(active.id);
    const overId = over ? String(over.id) : null;
    const activeColumn = findColumn(activeId);
    const overColumn = findColumn(overId);
    if (!activeColumn || !overColumn || activeColumn !== overColumn) {
      return null;
    }
    const activeIndex = activeColumn.items.findIndex(i => i.id === activeId);
    const overIndex = overColumn.items.findIndex(i => i.id === overId);
    if (activeIndex !== overIndex) {
      setCategoriesData(prev => {
        return prev.map(column => {
          if (column.id === activeColumn.id) {
            column.items = arrayMove(overColumn.items, activeIndex, overIndex);
            return column;
          } else {
            return column;
          }
        });
      });

      setSearchParams(unsavedParam);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const isSaveButtonDisabled =
    !menuFormValues.name ||
    !menuFormValues.location ||
    !menuFormValues.internalType ||
    !menuFormValues.workStartTime ||
    !menuFormValues.workEndTime;

  const isLoading =
    isGetMenuLoading ||
    isUpdateMenuLoading ||
    isCreateMenuLoading ||
    isPublishMenuLoading;

  {
    hasCheckedItems ? (
      <Button.Heading status="danger" onClick={handleDeleteCheckedItems}>
        Delete {checkedItemsNumber} products
      </Button.Heading>
    ) : (
      <FlexContainer $gap={10}>
        <Button.Heading
          disabled={
            (isEdit && !canUpdateMenu) ||
            (!isEdit && !canCreateMenu) ||
            isSaveButtonDisabled ||
            isCreateMenuLoading ||
            isUpdateMenuLoading ||
            isPublishMenuLoading
          }
          onClick={onPublishClick}
        >
          Publish
        </Button.Heading>

        <Button.Heading
          type="primary"
          disabled={
            (isEdit && !canUpdateMenu) ||
            (!isEdit && !canCreateMenu) ||
            isSaveButtonDisabled ||
            isPublishMenuLoading ||
            isCreateMenuLoading ||
            isUpdateMenuLoading
          }
          onClick={onSaveClick}
        >
          Save
        </Button.Heading>
      </FlexContainer>
    );
  }

  const disabledProductsIds = useMemo(
    () =>
      categoriesData.flatMap(category =>
        category.items.map(item => item.product.id),
      ),
    [categoriesData],
  );

  const dropDownMenuItems = hasCheckedItems
    ? [
        {
          key: generateUniqueId(),
          label: `Delete ${checkedItemsNumber} products`,
          onClick: handleDeleteCheckedItems,
        },
      ]
    : [
        {
          key: generateUniqueId(),
          label: "Publish",
          disabled:
            (isEdit && !canUpdateMenu) ||
            (!isEdit && !canCreateMenu) ||
            isSaveButtonDisabled ||
            isCreateMenuLoading ||
            isUpdateMenuLoading ||
            isPublishMenuLoading,
          onClick: onPublishClick,
        },
        {
          key: generateUniqueId(),
          label: "Save",
          disabled:
            (isEdit && !canUpdateMenu) ||
            (!isEdit && !canCreateMenu) ||
            isSaveButtonDisabled ||
            isPublishMenuLoading ||
            isCreateMenuLoading ||
            isUpdateMenuLoading,
          onClick: onPublishClick,
        },
      ];

  return (
    <>
      {isLoading && <Loader />}

      <FlexContainer $column $gap={24}>
        <PageStikyHeading $column $gap={24} $stiky={isDesktop}>
          <PageHeadingContainer $align="center" $justify="space-between">
            <PageTitleContainer $column $gap={16}>
              <ArrowBackLink
                title="Back to Menus"
                path={ADMIN_ROUTES.MENUS.path}
              />
              <Typography.H1>{isEdit ? "Edit" : "Add"} Menu</Typography.H1>
            </PageTitleContainer>

            {!isDesktop ? (
              <DropDown trigger={["click"]} items={dropDownMenuItems}>
                <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
              </DropDown>
            ) : (
              <>
                {hasCheckedItems ? (
                  <Button.Heading
                    status="danger"
                    onClick={handleDeleteCheckedItems}
                  >
                    Delete {checkedItemsNumber} products
                  </Button.Heading>
                ) : (
                  <FlexContainer $gap={10}>
                    <Button.Heading
                      disabled={
                        (isEdit && !canUpdateMenu) ||
                        (!isEdit && !canCreateMenu) ||
                        isSaveButtonDisabled ||
                        isCreateMenuLoading ||
                        isUpdateMenuLoading ||
                        isPublishMenuLoading
                      }
                      onClick={onPublishClick}
                    >
                      Publish
                    </Button.Heading>

                    <Button.Heading
                      type="primary"
                      disabled={
                        (isEdit && !canUpdateMenu) ||
                        (!isEdit && !canCreateMenu) ||
                        isSaveButtonDisabled ||
                        isPublishMenuLoading ||
                        isCreateMenuLoading ||
                        isUpdateMenuLoading
                      }
                      onClick={onSaveClick}
                    >
                      Save
                    </Button.Heading>
                  </FlexContainer>
                )}
              </>
            )}
          </PageHeadingContainer>
        </PageStikyHeading>

        <MenuForm
          isEdit={isEdit}
          values={menuFormValues}
          locationsData={locationsData}
          handleFormFieldChange={handleFormFieldChange}
        />

        <PageStikyCategories $stiky={isDesktop}>
          <Categories
            activeCategory={activeCategory}
            categoriesData={categoriesData}
            handleActiveCategory={handleActiveCategory}
            handleCategoriesData={handleCategoriesData}
            handleOpenCategoryModal={handleOpenCategoryModal}
          />
        </PageStikyCategories>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
        >
          {!!categoriesData?.length &&
            categoriesData?.map(({ id, name, items, disabled }) => (
              <Category
                key={id}
                disabled={disabled}
                categoryId={id}
                title={name}
                categoryItems={items}
                handleOpenCategoryModal={handleOpenCategoryModal}
                handleOpenCategoryItemsModal={handleOpenCategoryItemsModal}
                handleCategoryItemsData={handleCategoryItemsData}
                handleDisableCategory={handleDisableCategory}
                handleDeleteCategory={handleDeleteCategory}
              >
                {!!items?.length &&
                  items.map(item => (
                    <MenuItemCard
                      draggable
                      editable
                      key={item.id}
                      itemId={item.id}
                      image={item.product?.imageUrl}
                      modifiers={item?.modifiers}
                      title={item.product?.name}
                      labels={getLabel(item.product)}
                      description={item.product?.description}
                      kcal={item.product?.kcal}
                      kwd={item.product?.price}
                      tags={generateTags(item.product)}
                      checked={item.checked}
                      disabled={item.disabled}
                      onChange={(e, itemId) =>
                        handleCheckItem(id, itemId, e.target.checked)
                      }
                      handleDeleteItem={itemId =>
                        handleDeleteCategoryItem(id, [itemId])
                      }
                      handleEditItem={itemId =>
                        handleOpenCategoryItemModal(id, itemId)
                      }
                      handleDisableItem={itemId =>
                        handleDisableCategoryItem(id, itemId)
                      }
                    />
                  ))}
              </Category>
            ))}
        </DndContext>
      </FlexContainer>

      <CategoryForm
        open={!!showCategoryModal}
        categoryData={showCategoryModal}
        handleAddCategory={handleAddCategory}
        handleEditCategoryData={handleEditCategoryData}
        handleClose={handleCloseCategoryModal}
      />

      <CategoryItemForm
        open={!!showCategoryItemModal}
        locationId={menuFormValues.location}
        itemData={showCategoryItemModal}
        handleClose={handleCloseCategoryItemModal}
        handleSave={handleSaveCategoryItem}
        handleDelete={handleDeleteCurrentCategoryItem}
      />

      <AddItemsModal
        open={!!showCategoryItemsModal}
        locationId={menuFormValues.location}
        disabledProductsIds={disabledProductsIds}
        category={showCategoryItemsModal}
        handleClose={handleCloseCategoryItemsModal}
        handleAddItemsToCategory={handleAddItemsToCategory}
      />

      <ConfirmDialog
        open={!!deleteItemsModal}
        Icon={TrashIcon}
        message={deleteItemsModal?.message}
        description={deleteItemsModal?.description}
        firstCTAButton={{
          title: deleteItemsModal?.buttonTitle,
          status: "danger",
          onClick: () => onDeleteConfirm(),
        }}
        onCancel={handleCloseDeleteModal}
      />

      <ConfirmDialog
        open={unavailableItemsModal.show}
        Icon={ProductIcon}
        message={`Please pay attention that the menu you publish contains ${unavailableItemsModal.count} items with zero quantity.`}
        description={
          "Confirm publishing so these items will have snoozed status or change the quantity in the Inventory"
        }
        firstCTAButton={{
          title: "Inventory",
          type: "primary",
          onClick: () => onInventoryClick(),
        }}
        secondCTAButton={{
          title: "Publish",
          status: "danger",
          onClick: () => onPublishConfirm(),
        }}
        onCancel={handleClosePublishConfirmModal}
      />
    </>
  );
};
