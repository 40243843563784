import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

const schema = object({
  name: string()
    .trim()
    .max(30, "Name must be less than 30 characters")
    .required("URL is required"),
  name2: string()
    .trim()
    .max(30, "Name must be less than 30 characters")
    .optional(),
}).required();

export const resolver = yupResolver(schema);
