// Lib
import { FC } from "react";
import { Col, Row } from "antd";
// Hooks
import { useViewport } from "hooks";
// Types
import {
  EPaymentMethod,
  OrderService,
  OrderServiceLabel,
  OrderTypeLabel,
} from "types/orders";
import { SummaryTabProps } from "./types";
// Constants
import { FULL_MONTH_DAY_YEAR_HOURS_MINUTES_DATE } from "consts";
// Helpers
import { dateTransform, fixedDigitsValue } from "helpers/dataHelpers";
import { createTableData } from "./helpers";
// Components
import { InfoItem, SummaryItem } from "components";
// Styled
import { Typography } from "styled/Typography";
import { ContentBox, FlexContainer, InputsGridContainer } from "styled/Box";
import { OrderInfoTable } from "../../styled";

import profile from "images/profile.png";

import { columns } from "./config";

export const SummaryTab: FC<SummaryTabProps> = ({ orderData, isLoading }) => {
  const { isMiddleDesktop } = useViewport();

  const isFmcCentsPaid = orderData?.paymentMethod === EPaymentMethod.FMC;

  const tableData = createTableData(orderData);

  const currency = isFmcCentsPaid ? "FM Coins" : "KWD";

  const subtotal = orderData?.orderItems?.reduce((acc, el) => {
    const price = isFmcCentsPaid ? el.fmcCentsPrice : el.fiatCentsPrice;

    const productsQuantity = el?.quantity || 1;

    acc = (acc * 1000 + Number(price) * productsQuantity * 1000) / 1000;

    if (el.modifiers?.length) {
      el.modifiers.forEach(modifier => {
        const modifiersQuantity = modifier?.quantity || 1;

        const modifierPrice = isFmcCentsPaid
          ? modifier?.fcmCentsPrice
          : modifier?.fiatCentsPrice;

        acc =
          (acc * 1000 +
            Number(modifierPrice) *
              modifiersQuantity *
              productsQuantity *
              1000) /
          1000;
      });
    }

    return acc;
  }, 0);

  const subtotalTitle = `${fixedDigitsValue(subtotal, 2)} ${currency}`;

  const totalTitle = `${
    isFmcCentsPaid
      ? orderData?.totalFmcCentsPrice || 0
      : orderData?.totalFiatCentsPrice || 0
  } ${currency}`;

  const paymentAmountTitle = `${
    isFmcCentsPaid
      ? orderData?.totalFmcCentsPrice || 0
      : orderData?.totalFiatCentsPrice || 0
  } ${currency}`;

  const discountTitle = `${
    isFmcCentsPaid
      ? orderData?.discountFiatTotal || 0
      : orderData?.discountFmcTotal || 0
  } ${currency}`;

  const deliveryCostTitle = `${
    isFmcCentsPaid
      ? orderData?.deliveryFeeFmcCents || 0
      : orderData?.deliveryFeeFiatCents || 0
  } ${currency}`;

  return (
    <>
      <Row>
        <Col className="gutter-row" span={isMiddleDesktop ? 14 : 24}>
          <ContentBox $column $margin={isMiddleDesktop ? "16px 0" : "0 0 16px"}>
            <Typography.H2>Order Summary</Typography.H2>

            <OrderInfoTable
              shadow={false}
              loading={isLoading}
              dataSource={tableData}
              columns={columns}
            />

            <div>
              <SummaryItem
                isLoading={isLoading}
                label="Subtotal"
                value={subtotalTitle}
              />

              <SummaryItem
                isLoading={isLoading}
                label="Discount"
                value={discountTitle}
              />

              <SummaryItem
                isLoading={isLoading}
                label="Delivery"
                value={deliveryCostTitle}
              />

              <SummaryItem
                isLoading={isLoading}
                label="Total"
                value={totalTitle}
              />

              <SummaryItem
                isLoading={isLoading}
                label="Payment amount"
                value={paymentAmountTitle}
              />

              <SummaryItem
                isLoading={isLoading}
                label="Payment method"
                value={currency}
              />

              <SummaryItem
                isLoading={isLoading}
                label="Order is already paid"
                iconType={orderData?.orderIsAlreadyPaid ? "check" : "cancel"}
                value={orderData?.orderIsAlreadyPaid ? "Yes" : "No"}
              />
            </div>
          </ContentBox>

          <ContentBox $column $margin={isMiddleDesktop ? "16px 0" : "0 0 16px"}>
            <Typography.H2>Delivery Information</Typography.H2>

            <InfoItem
              isLoading={isLoading}
              type="location"
              label="Address"
              info={orderData?.deliveryAddress}
            />

            <FlexContainer $justify="space-between">
              {/* temporary removed */}
              {/* <Col span={12}>
                
                <InfoItem
                  type="menu"
                  label="Order type"
                  info={orderData?.type}
                />
              </Col> */}

              <Col span={12}>
                {/* //TODO: add correct data */}
                <InfoItem
                  isLoading={isLoading}
                  type="menu"
                  label="Courier info"
                  info={orderData?.courierInfo}
                />
              </Col>
            </FlexContainer>
          </ContentBox>
        </Col>

        <Col className="gutter-row" span={isMiddleDesktop ? 10 : 24}>
          <ContentBox $column $margin={isMiddleDesktop ? "16px" : "0 0 16px"}>
            <Typography.H2>Order Info</Typography.H2>

            <InputsGridContainer>
              <InfoItem
                isLoading={isLoading}
                type="schedule"
                label="Pickup Time"
                info={
                  orderData?.pickupTime &&
                  dateTransform({
                    date: orderData?.pickupTime,
                    format: FULL_MONTH_DAY_YEAR_HOURS_MINUTES_DATE,
                  })
                }
              />

              <InfoItem
                isLoading={isLoading}
                type="schedule"
                label="Order time"
                info={
                  orderData?.createdAt &&
                  dateTransform({
                    date: orderData?.createdAt,
                    format: FULL_MONTH_DAY_YEAR_HOURS_MINUTES_DATE,
                  })
                }
              />

              <InfoItem
                isLoading={isLoading}
                type="label"
                label="Channel Order ID"
                info={orderData?.sid}
              />

              <InfoItem
                isLoading={isLoading}
                type="description"
                label="Order Note"
                info={orderData?.note || "No note available for this order"}
              />

              <InfoItem
                isLoading={isLoading}
                type="label"
                label="Service"
                info={OrderServiceLabel[OrderService.SWISH]}
              />

              <InfoItem
                isLoading={isLoading}
                type="label"
                label="Type"
                info={
                  orderData?.orderType
                    ? OrderTypeLabel[orderData?.orderType]
                    : ""
                }
              />
            </InputsGridContainer>
          </ContentBox>

          <ContentBox $column $margin={isMiddleDesktop ? "16px" : "0 0 16px"}>
            <Typography.H2>Customer Informations</Typography.H2>

            <FlexContainer $justify="space-between">
              <Col span={12}>
                <InfoItem
                  isLoading={isLoading}
                  type="profile"
                  label="Customer"
                  profileUrl={profile}
                  info={orderData?.customerName}
                />
              </Col>

              <Col span={12}>
                <InfoItem
                  isLoading={isLoading}
                  type="call"
                  label="Phone"
                  info={orderData?.phoneNumber}
                />
              </Col>
            </FlexContainer>

            <InfoItem
              isLoading={isLoading}
              type="email"
              label="E-mail"
              info={orderData?.email}
            />
          </ContentBox>

          {/* temporary removed */}
          {/* <ContentBox column margin="16px">
            <Typography.H2>Integration Info</Typography.H2>

            <InfoItem type="tag" label="ID" info={orderInfo?.id} />

            <InfoItem type="grid" label="App" info={"Forevermore, Symphony"} />
          </ContentBox> */}
        </Col>
      </Row>
    </>
  );
};
