import { NOTIFICATIONS } from "consts/notifications";
import {
  KitchenNotificationSoundDurationOptions,
  KitchenNotificationSoundDurationOptionsLabels,
  KitchenNotitficationSoundOptions,
  KitchenNotitficationSoundOptionsLabels,
  KitchenPosProviderOptions,
  KitchenPosProviderOptionsLabels,
  NotificationSoundOption,
  PreparationTimeOption,
  PreparationTimeOptions,
  PreparationTimeOptionsLabels,
  SoundDurationOption,
  PosProviderOption,
} from "types/kitchen";

export const columns = [
  {
    title: "Setting",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Option",
    dataIndex: "value",
    key: "value",
    width: 180,
  },
];

export enum SettingsKeys {
  DisplayItemPrices = "displayItemPrices",
  AutomaticallyAcceptOrder = "automaticallyAcceptOrder",
  AllowCancelingOrders = "allowCancelingOrders",
  NotificationSoundDuration = "notificationSoundDuration",
  NotificationSound = "notificationSound",
  ManualHandleDelivery = "manualHandleDelivery",
  SnoozeIfOutOfStock = "snoozeIfOutOfStock",
  UnSnoozeIfStock = "unSnoozeIfStock",
  NotificationPlaySound = "notificationPlaySound",
  DefaultPreparationTime = "defaultPreparationTime",
  PosProvider = "posProvider",
}

export enum SettingsLabels {
  allowCancelingOrders = "Allow canceling orders",
  displayItemPrices = "Show order items price",
  automaticallyAcceptOrder = "Automatically accept the orders",

  defaultPreparationTime = "Preparation time before Pick-up (minutes)",
  defaultDeliveryTime = "Default delivery time (minutes)",
  manualHandleDelivery = "Disable buttons 'In Delivery' and 'Mark as Delivered'",
  posProvider = "POS-terminal provider'",
  snoozeIfOutOfStock = "Automatically  snooze items if out of stock",
  unSnoozeIfStock = "Automatically  unsnooze items if >=1",

  notificationPlaySound = "Play a sound when a new order comes in",
  notificationSound = "New order sounds audio name",
  notificationSoundDuration = "Sound duration",
}

export const preparationTimeOptions: PreparationTimeOption[] = [
  {
    label: PreparationTimeOptionsLabels.ASAP,
    value: PreparationTimeOptions.ASAP,
  },
  {
    label: PreparationTimeOptionsLabels.PLUS_5_MIN,
    value: PreparationTimeOptions.PLUS_5_MIN,
  },
  {
    label: PreparationTimeOptionsLabels.PLUS_10_MIN,
    value: PreparationTimeOptions.PLUS_10_MIN,
  },
  {
    label: PreparationTimeOptionsLabels.PLUS_15_MIN,
    value: PreparationTimeOptions.PLUS_15_MIN,
  },
  {
    label: PreparationTimeOptionsLabels.PLUS_30_MIN,
    value: PreparationTimeOptions.PLUS_30_MIN,
  },
];

export const soundDurationOptions: SoundDurationOption[] = [
  {
    label: KitchenNotificationSoundDurationOptionsLabels.FIVE_SEСONDS,
    value: KitchenNotificationSoundDurationOptions.FIVE_SEСONDS,
  },
  {
    label: KitchenNotificationSoundDurationOptionsLabels.EIGHT_SECONDS,
    value: KitchenNotificationSoundDurationOptions.EIGHT_SECONDS,
  },
  {
    label: KitchenNotificationSoundDurationOptionsLabels.TEN_SECONDS,
    value: KitchenNotificationSoundDurationOptions.TEN_SECONDS,
  },
  {
    label: KitchenNotificationSoundDurationOptionsLabels.FIFTEEN_SECONDS,
    value: KitchenNotificationSoundDurationOptions.FIFTEEN_SECONDS,
  },
  {
    label: KitchenNotificationSoundDurationOptionsLabels.TWENTY_SECONDS,
    value: KitchenNotificationSoundDurationOptions.TWENTY_SECONDS,
  },
];

export const posProviderOptions: PosProviderOption[] = [
  {
    label: KitchenPosProviderOptionsLabels.foodics,
    value: KitchenPosProviderOptions.FOODICS,
  },
  {
    label: KitchenPosProviderOptionsLabels.symphony,
    value: KitchenPosProviderOptions.SYMPHONY,
  },
];

export const notificationSoundOptions: NotificationSoundOption[] = [
  {
    label:
      KitchenNotitficationSoundOptionsLabels[
        KitchenNotitficationSoundOptions.ALERT
      ],
    value: KitchenNotitficationSoundOptions.ALERT,
  },
  {
    label:
      KitchenNotitficationSoundOptionsLabels[
        KitchenNotitficationSoundOptions.DEFINITE
      ],
    value: KitchenNotitficationSoundOptions.DEFINITE,
  },
  {
    label:
      KitchenNotitficationSoundOptionsLabels[
        KitchenNotitficationSoundOptions.NOTIFICATION
      ],
    value: KitchenNotitficationSoundOptions.NOTIFICATION,
  },
  {
    label:
      KitchenNotitficationSoundOptionsLabels[
        KitchenNotitficationSoundOptions.MUSIC_BOX
      ],
    value: KitchenNotitficationSoundOptions.MUSIC_BOX,
  },
  {
    label:
      KitchenNotitficationSoundOptionsLabels[
        KitchenNotitficationSoundOptions.SIREN
      ],
    value: KitchenNotitficationSoundOptions.SIREN,
  },
  {
    label:
      KitchenNotitficationSoundOptionsLabels[
        KitchenNotitficationSoundOptions.RUSH
      ],
    value: KitchenNotitficationSoundOptions.RUSH,
  },
];

export const internetConnectionNotificationOptions = [
  { label: "5 mins", value: 5 },
  { label: "10 mins", value: 10 },
  { label: "15 mins", value: 15 },
];

export const notificationMessage = {
  [SettingsKeys.DisplayItemPrices]: NOTIFICATIONS.ORDERS_SETTINGS_UPDATED,
  [SettingsKeys.PosProvider]: NOTIFICATIONS.ORDERS_SETTINGS_UPDATED,
  [SettingsKeys.AutomaticallyAcceptOrder]:
    NOTIFICATIONS.ORDERS_SETTINGS_UPDATED,
  [SettingsKeys.ManualHandleDelivery]: NOTIFICATIONS.ORDERS_SETTINGS_UPDATED,
  [SettingsKeys.SnoozeIfOutOfStock]: NOTIFICATIONS.ORDERS_SETTINGS_UPDATED,
  [SettingsKeys.UnSnoozeIfStock]: NOTIFICATIONS.ORDERS_SETTINGS_UPDATED,
  [SettingsKeys.AllowCancelingOrders]: NOTIFICATIONS.ORDERS_SETTINGS_UPDATED,
  [SettingsKeys.DefaultPreparationTime]: NOTIFICATIONS.ORDERS_SETTINGS_UPDATED,

  [SettingsKeys.NotificationSound]:
    NOTIFICATIONS.NOTIFICATIONS_SETTINGS_UPDATED,
  [SettingsKeys.NotificationPlaySound]:
    NOTIFICATIONS.NOTIFICATIONS_SETTINGS_UPDATED,
  [SettingsKeys.NotificationSoundDuration]:
    NOTIFICATIONS.NOTIFICATIONS_SETTINGS_UPDATED,
};
