// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  CreateProductRequest,
  UpdateProductRequest,
  GetProductResponse,
  DeleteProductsRequest,
  GetProductsRequest,
  GetProductsResponse,
  DeleteProductTagRequest,
  CreateProductTagRequest,
  UpdateProductTagRequest,
  GetProductTagsListResponse,
  GetProductLabelsResponse,
} from "types/products";
// Helpers
import { productsUrlBuilder } from "./urlBuilder/products";
// Utils
import { axiosBaseQuery } from "utils/baseQuery";
import {
  getProductResponseMapper,
  updateProductPayloadMapper,
} from "mappers/products";

export const productsAPI = createApi({
  reducerPath: "productsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ["products", "labels", "alergents", "highlights"],
  endpoints: build => ({
    getProducts: build.query<GetProductsResponse, GetProductsRequest>({
      query: ({ companyId, locationId, query }) => ({
        url: productsUrlBuilder.getProducts({ companyId, locationId, query }),
        method: "GET",
      }),
      providesTags: ["products"],
    }),
    getProduct: build.query<GetProductResponse, string>({
      query: id => ({
        url: productsUrlBuilder.getProduct(id),
        method: "GET",
      }),
      providesTags: ["products"],
      transformResponse: (res: GetProductResponse) =>
        getProductResponseMapper(res),
    }),
    createProduct: build.mutation<{ id: string }, CreateProductRequest>({
      query: ({ companyId, data }) => ({
        url: productsUrlBuilder.createProduct(companyId),
        method: "POST",
        data,
      }),
      invalidatesTags: ["products"],
    }),
    updateProduct: build.mutation<void, UpdateProductRequest>({
      query: ({ id, data }) => ({
        url: productsUrlBuilder.updateProduct(id),
        method: "PATCH",
        data: updateProductPayloadMapper(data),
      }),
      invalidatesTags: ["products"],
    }),
    deleteProducts: build.mutation<void, DeleteProductsRequest>({
      query: ({ data }) => ({
        url: productsUrlBuilder.deleteProducts(),
        method: "DELETE",
        data,
      }),
      invalidatesTags: ["products"],
    }),
    getProductHighlights: build.query<GetProductTagsListResponse, void>({
      query: () => ({
        url: productsUrlBuilder.getProductHightlights(),
        method: "GET",
      }),
      providesTags: ["highlights"],
    }),
    createProductHighlight: build.mutation<void, CreateProductTagRequest>({
      query: data => ({
        url: productsUrlBuilder.getProductHightlights(),
        method: "POST",
        data,
      }),
      invalidatesTags: ["highlights"],
    }),
    updateProductHighlight: build.mutation<void, UpdateProductTagRequest>({
      query: ({ id, ...data }) => ({
        url: productsUrlBuilder.updateProductHightlight(id),
        method: "PUT",
        data,
      }),
      invalidatesTags: ["highlights"],
    }),
    deleteProductHighlight: build.mutation<void, DeleteProductTagRequest>({
      query: ({ id }) => ({
        url: productsUrlBuilder.deleteProductHightlight(id),
        method: "DELETE",
      }),
      invalidatesTags: ["highlights"],
    }),
    getProductAlergents: build.query<GetProductTagsListResponse, void>({
      query: () => ({
        url: productsUrlBuilder.getProductAlergents(),
        method: "GET",
      }),
      providesTags: ["alergents"],
    }),
    createProductAlergent: build.mutation<void, CreateProductTagRequest>({
      query: data => ({
        url: productsUrlBuilder.getProductAlergents(),
        method: "POST",
        data,
      }),
      invalidatesTags: ["alergents"],
    }),
    updateProductAlergent: build.mutation<void, UpdateProductTagRequest>({
      query: ({ id, ...data }) => ({
        url: productsUrlBuilder.updateProductAlergent(id),
        method: "PUT",
        data,
      }),
      invalidatesTags: ["alergents"],
    }),
    deleteProductAlergent: build.mutation<void, DeleteProductTagRequest>({
      query: ({ id }) => ({
        url: productsUrlBuilder.deleteProductAlergent(id),
        method: "DELETE",
      }),
      invalidatesTags: ["alergents"],
    }),
    getProductsLabels: build.query<GetProductLabelsResponse, void>({
      query: () => ({
        url: productsUrlBuilder.getProductsLabels(),
        method: "GET",
      }),
      providesTags: ["labels"],
    }),
    createProductLabel: build.mutation<void, CreateProductTagRequest>({
      query: data => ({
        url: productsUrlBuilder.createProductLabel(),
        method: "POST",
        data,
      }),
      invalidatesTags: ["labels"],
    }),
    updateProductLabel: build.mutation<void, UpdateProductTagRequest>({
      query: ({ id, ...data }) => ({
        url: productsUrlBuilder.updateProductLabel(id),
        method: "PUT",
        data,
      }),
      invalidatesTags: ["labels"],
    }),
    deleteProductLabel: build.mutation<void, DeleteProductTagRequest>({
      query: ({ id }) => ({
        url: productsUrlBuilder.deleteProductLabel(id),
        method: "DELETE",
      }),
      invalidatesTags: ["labels"],
    }),
    syncProducts: build.query<void, { companyId: string }>({
      query: ({ companyId }) => ({
        url: productsUrlBuilder.syncProducts(companyId),
        method: "GET",
      }),
    }),
    syncLocationProducts: build.query<void, { locationId: string }>({
      query: ({ locationId }) => ({
        url: productsUrlBuilder.syncLocationProducts(locationId),
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetProductQuery,
  useGetProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductsMutation,
  useGetProductHighlightsQuery,
  useCreateProductHighlightMutation,
  useUpdateProductHighlightMutation,
  useDeleteProductHighlightMutation,
  useGetProductAlergentsQuery,
  useUpdateProductAlergentMutation,
  useCreateProductAlergentMutation,
  useDeleteProductAlergentMutation,
  useGetProductsLabelsQuery,
  useCreateProductLabelMutation,
  useUpdateProductLabelMutation,
  useDeleteProductLabelMutation,
  useLazySyncProductsQuery,
  useLazySyncLocationProductsQuery,
} = productsAPI;
