import { ProductFormTypes } from "./types";

export const defaultValues: ProductFormTypes = {
  name: "",
  name2: "",
  description: "",
  imageUrl: null,
  status: "disabled",
  carbs: "",
  kcal: "",
  protein: "",
  fat: "",
  sugar: "",
  plu: "",
  price: "",
  tax: "",
  fiber: "",
  cholesterol: "",
  zinc: "",
  calsium: "",
  pottasium: "",
  vitaminA: "",
  vitaminC: "",
  highlights: {},
  alergents: {},
  labels: "",
  isNewArrival: false,
  externalFiatPrice: "0",
};
