import { CENTS_IN_COIN_RATE } from "consts/rate";

export const devideByCentsInCoinRate = (value?: number | null) => {
  if (!value) {
    return 0;
  }
  return (value * 1000) / CENTS_IN_COIN_RATE / 1000;
};

export const multiplyByCentsInCoinRate = (value?: number | null) => {
  if (!value) {
    return 0;
  }
  return (value * 1000 * CENTS_IN_COIN_RATE) / 1000;
};

export const fixedDigitsValue = (
  data: number | string | null | undefined,
  digits?: number,
): string | number | null | undefined => {
  if (typeof data === "number") {
    if (digits) {
      return data?.toFixed(digits);
    }

    return data;
  }

  if (typeof data === "string") {
    if (digits) {
      return Number(data)?.toFixed(digits);
    }

    return data;
  }

  return data;
};

export const validateNumericStringValue = (
  value: string | undefined,
): string => {
  if (!value) {
    return "0";
  }

  const numericString = value !== undefined && /^-?\d+(\.\d+)?$/.test(value);

  if (!numericString) {
    return "0";
  }

  return value;
};

export const multiplyStringByCentsInCoinRate = (
  value?: string | null,
  digits = 2,
): string => {
  const numericValue = Number(validateNumericStringValue(value));

  const multipliedValue = (numericValue * 1000 * CENTS_IN_COIN_RATE) / 1000;

  return fixedDigitsValue(multipliedValue, digits).toString();
};

export const devideByStringByCentsInCoinRate = (
  value?: string | null,
  digits = 2,
): string => {
  const numericValue = Number(validateNumericStringValue(value));

  const devidedValue = (numericValue * 1000) / CENTS_IN_COIN_RATE / 1000;

  return fixedDigitsValue(devidedValue, digits).toString();
};
