import { FC } from "react";

import { ModifierType, StatusType, UrlQueryParams } from "./common";
import { SelectProps, SwitchProps } from "components/Form";
import {
  EPaymentMethod,
  OrderHistories,
  OrderItem,
  OrderStatus,
} from "./orders";

export interface ActiveKitchen {
  id: string;
  name: string;
}

export enum KitchenPosProviderOptions {
  SYMPHONY = "symphony",
  FOODICS = "foodics",
}

export enum KitchenPosProviderOptionsLabels {
  symphony = "Symphony",
  foodics = "Foodics",
}

export enum KitchenNotitficationSoundOptions {
  ALERT = "alert",
  DEFINITE = "definite",
  NOTIFICATION = "notification",
  MUSIC_BOX = "music_box",
  SIREN = "siren",
  RUSH = "rush",
}

export enum KitchenNotitficationSoundOptionsLabels {
  alert = "Alert",
  definite = "Definite",
  notification = "Notification",
  music_box = "Music box",
  siren = "Siren",
  rush = "Rush",
}

export enum KitchenNotificationSoundDurationOptions {
  FIVE_SEСONDS = 5000,
  EIGHT_SECONDS = 8000,
  TEN_SECONDS = 10000,
  FIFTEEN_SECONDS = 15000,
  TWENTY_SECONDS = 20000,
}

export enum KitchenNotificationSoundDurationOptionsLabels {
  FIVE_SEСONDS = "5 sec",
  EIGHT_SECONDS = "8 sec",
  TEN_SECONDS = "10 sec",
  FIFTEEN_SECONDS = "15 sec",
  TWENTY_SECONDS = "20 sec",
}

export enum PreparationTimeOptions {
  ASAP = 0,
  PLUS_5_MIN = 5,
  PLUS_10_MIN = 10,
  PLUS_15_MIN = 15,
  PLUS_30_MIN = 30,
}

export enum PreparationTimeOptionsLabels {
  ASAP = "ASAP",
  PLUS_5_MIN = "+5 min",
  PLUS_10_MIN = "+10 min",
  PLUS_15_MIN = "+15 min",
  PLUS_30_MIN = "+30 min",
}

export enum OrderTransitionTimeOptions {
  PLUS_15_MIN = 15,
  PLUS_30_MIN = 30,
  PLUS_1_HOUR = 60,
  PLUS_2_HOURS = 120,
}

export enum OrderTransitionTimeOptionsLabels {
  PLUS_15_MIN = "+15 min",
  PLUS_30_MIN = "+30 min",
  PLUS_1_HOUR = "+1 h",
  PLUS_2_HOURS = "+2 h",
}

export interface KitchenSettings {
  automaticallyAcceptOrder: boolean;
  orderTransitionTime: OrderTransitionTimeOptions;
  automaticallyFinalizeOrders: boolean;
  allowIndefiniteSnoozing: boolean;
  displayItemPrices: boolean;
  loadOrdersManually: boolean;
  allowCancelingOrders: boolean;
  displayCourierName: boolean;
  notificationPlaySound: boolean;
  notificationSoundDuration: KitchenNotificationSoundDurationOptions;
  notificationSound: KitchenNotitficationSoundOptions;
  defaultDeliveryTime: number;
  defaultPreparationTime: number;
  manualHandleDelivery: boolean;
  snoozeIfOutOfStock: boolean;
  unSnoozeIfStock: boolean;
}

export interface KitchenSetting {
  key: keyof KitchenSettings;
  value: KitchenSettings[keyof KitchenSettings];
}

export interface NotificationSoundOption {
  label: KitchenNotitficationSoundOptionsLabels;
  value: KitchenNotitficationSoundOptions;
}

export interface PosProviderOption {
  label: KitchenPosProviderOptionsLabels;
  value: KitchenPosProviderOptions;
}

export interface SoundDurationOption {
  label: KitchenNotificationSoundDurationOptionsLabels;
  value: KitchenNotificationSoundDurationOptions;
}

export interface OrderTransitionTimeOption {
  label: OrderTransitionTimeOptionsLabels;
  value: OrderTransitionTimeOptions;
}

export interface PreparationTimeOption {
  label: PreparationTimeOptionsLabels;
  value: PreparationTimeOptions;
}

export type BooleanField = {
  type: "boolean";
  Component: React.ForwardRefExoticComponent<
    SwitchProps & React.RefAttributes<HTMLElement>
  >;
};
export type SelectField = {
  type: "select";
  Component: FC<SelectProps>;
  options: (
    | PreparationTimeOption
    | OrderTransitionTimeOption
    | SoundDurationOption
    | NotificationSoundOption
  )[];
};

export interface KitchenState {
  activeKitchen: null | ActiveKitchen;

  filters: {
    orderBy: KitchenOrderFilterValues;
    orderDirection: KitchenOrderFilterValues;
  };
  settings: KitchenSettings;
}

export enum KitchenOrderTab {
  NEW = "New",
  INPROGRESS = "In Progress",
  FINISHED = "Finished",
  SCHEDULED = "Scheduled",
}

export const kitchenTabsStatuses = {
  [KitchenOrderTab.NEW]: [OrderStatus.PENDING],
  [KitchenOrderTab.INPROGRESS]: [OrderStatus.ACCEPTED, OrderStatus.PREPARING],
  [KitchenOrderTab.FINISHED]: [
    OrderStatus.READY_FOR_PICKUP,
    OrderStatus.PREPARED,
    OrderStatus.IN_DELIVERY,
    OrderStatus.DELIVERED,
    OrderStatus.CANCELED,
    OrderStatus.AUTO_FINALIZED,
  ],
  [KitchenOrderTab.SCHEDULED]: [OrderStatus.SCHEDULED],
};

export interface ChangeOrderStatus {
  id: string;
  next: OrderStatus;
  prev: OrderStatus;
}

export enum KitchenOrderFilterValues {
  PICKUP_TIME = "pickupTime",
  CREATED_AT = "createdAt",
  ASC = "ASC",
  DESC = "DESC",
}

export enum KitchenOrderFilterLabels {
  pickupTime = "By pickup time",
  createdAt = "By created time",
  ASC = "Oldest first",
  DESC = "Newest first",
}

export interface KitchenOrderItem {
  id: string;
  productPlu: string;
  productName: string;
  quantity: number;
  fmcCentsPrice: number;
  fiatCentsPrice: string;
  createdAt: Date;
  remark: string;
  modifiers: {
    id: string;
    productPlu: string;
    fcmCentsPrice: string;
    fiatCentsPrice: string;
    quantity?: number;
    product: {
      id: string;
      name: string;
      name2: string;
      imageUrl: null | string;
      externalFiatPrice: null | string;
      modifierType: ModifierType;
    };
  }[];
}

export interface KitchenOrder {
  id: string;
  status: OrderStatus;
  sid: string;
  pickupTime?: Date;
  customerName: string;
  paymentMethod: EPaymentMethod;
  note: string;
  totalFmcCentsPrice: number;
  totalFiatCentsPrice: number;
  companyId: string;
  orderItems: KitchenOrderItem[];
  type: number;
  deliveryAddress: string;
  phoneNumber: string;
  createdAt: Date;
}

export interface GetKitchenOrdersListRequest {
  siteId: string;
  query: UrlQueryParams;
}

export interface GetKitchenOrdersDetailsRequest {
  siteId: string;
  selectedOrder: KitchenOrder;
}

export interface UpdateKitchenOrderRequest {
  siteId: string;
  orderId: string;
  data: {
    status: OrderStatus;
  };
}

export interface KitchenChannelLinkRequestData {
  siteId: string;
  id: string;
  data: {
    name: string;
    status: StatusType;
    simphonyRevenuCenterId: string;
  };
}

export interface KitchenChannelLink {
  id: string;
  name: string;
  simphonyRevenuCenterId: string;
  status: StatusType;
}

export interface GetKitchenOrdersResponse {
  data: KitchenOrder[];
}

export interface GetKitchenOrderDetailsResponse {
  id: string;
  status: OrderStatus;
  deliverySystem: string;
  chargeStatus: string;
  paymentMethod: EPaymentMethod;
  orderIsAlreadyPaid: boolean;
  chargeFailureReason?: string;
  type: string;
  sid: string;
  pickupTime?: Date;
  deliveryAddress: string;
  courierInfo: string;
  customerName: string;
  phoneNumber: string;
  email: string;
  note: string;
  totalFmcCentsPrice: number;
  totalFiatCentsPrice: number;
  companyId: string;
  orderItems: OrderItem[];
  orderHistories: OrderHistories[];
  createdAt: Date;
}

export interface GetKitchenSettingsResponse {
  siteId: string;
  automaticallyAcceptOrder: boolean;
  orderTransitionTime: OrderTransitionTimeOptions;
  automaticallyFinalizeOrders: boolean;
  allowIndefiniteSnoozing: boolean;
  displayItemPrices: boolean;
  loadOrdersManually: boolean;
  allowCancelingOrders: boolean;
  displayCourierName: boolean;
  notificationPlaySound: boolean;
  notificationSoundDuration: KitchenNotificationSoundDurationOptions;
  notificationSound: KitchenNotitficationSoundOptions;
  defaultDeliveryTime: number;
  defaultPreparationTime: number;
  manualHandleDelivery: boolean;
  snoozeIfOutOfStock: boolean;
  posProvider: KitchenPosProviderOptions;
  unSnoozeIfStock: boolean;
}

export interface UpdateKitchenSettingsRequest {
  siteId: string;
  data: {
    automaticallyAcceptOrder?: boolean;
    defaultPreparationTime?: number;
    orderTransitionTime?: number;
    automaticallyFinalizeOrders?: boolean;
    allowIndefiniteSnoozing?: boolean;
    displayItemPrices?: boolean;
    loadOrdersManually?: boolean;
    allowCancelingOrders?: boolean;
    displayCourierName?: boolean;
    notificationPlaySound?: boolean;
    notificationSoundDuration?: KitchenNotificationSoundDurationOptions;
    notificationSound?: KitchenNotitficationSoundOptions;
  };
}

export interface UpdateLoopKitchenSettingsRequest
  extends UpdateKitchenSettingsRequest {
  companyId: string;
}
