import { GetProductResponse, UpdateProductRequest } from "types/products";
import {
  devideByStringByCentsInCoinRate,
  multiplyStringByCentsInCoinRate,
} from "utils/coinRates";

export const updateProductPayloadMapper = (
  data: UpdateProductRequest["data"],
): UpdateProductRequest["data"] => {
  return {
    ...data,
    discountPrice: multiplyStringByCentsInCoinRate(data?.discountPrice),
  };
};

export const getProductResponseMapper = (
  data: GetProductResponse,
): GetProductResponse => {
  return {
    ...data,
    discountPrice: devideByStringByCentsInCoinRate(data?.discountPrice),
  };
};
