import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

const schema = object({
  name: string()
    .trim()
    .min(3, "Product name must be more than 3 characters")
    .max(50, "Product name must be less than 50 characters")
    .required("Product name is required"),
  description: string().nullable(),
  image: string().nullable(),
  plu: string().nullable().trim(),
  price: string().nullable().trim(),
  tax: string().nullable().trim(),
}).required();

export const resolver = yupResolver(schema);
